<template>
  <div class="outer-page">
    <x-table :no-data-text="CA('ledgerProcessing_check') ? '暂无数据' : '暂无数据查看权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @add="add" @page-change="pageChange" @loadEnter="loadEnter" @loadExpend="loadExpend" @page-size-change="pageSizeChange" :showSummary="true" :summaryMethod="handleSummary"></x-table>
    <Modal v-model="modal.show" :title="modal.title" :width="900" @on-visible-change="modalChange" fullscreen>
      <Form ref="form" :model="form" :label-width="140" :rules="rules">
        <Row>
          <Col span="8">
          <FormItem label="企业名称" prop="farmerName">
            <Input clearable placeholder="请输入企业名称" v-model="form.farmerName" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="规模类型" prop="scaleInfo">
            <Select clearable palceholder="请选择规模类型" style="width: 260px" v-model="form.scaleInfo">
              <Option v-for="(item, index) in scaleList" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="省市区编码" prop="region">
            <Cascader :data="areaList" v-model="form.region" :load-data="loadData" change-on-select placeholder="请选择区域" style="width: 260px"></Cascader>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="养殖地址" prop="farmerAddress">
            <Input placeholder="请选择养殖地址" v-model="form.farmerAddress" style="width: 260px" readonly>
            <span slot="append" class="iconfont" style="cursor: pointer" @click="mapModelShow">&#xe648;</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="业主姓名" prop="legalName">
            <Input style="width: 260px" clearable placeholder="请输入业主姓名" v-model="form.legalName"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="业主手机号" prop="legalPhone">
            <Input style="width: 260px" clearable placeholder="请输入业主手机号" v-model="form.legalPhone"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="养殖品种" prop="typeName">
            <Input placeholder="请输入养殖品种" v-model="form.typeName" style="width: 260px"></Input>
            <!-- <Select v-model="form.typeInfo" multiple placeholder="请选择养殖品种" style="width: 260px">
              <Option v-for="(item, index) in catData" :key="index" :value="item.id + '/' + item.name">{{ item.name }}</Option>
            </Select> -->
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖用途">
            <Select clearable placeholder="请选择养殖用途" v-model="form.application" style="width: 260px">
              <Option v-for="(item, index) in application" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="圈舍面积(㎡)" prop="stableArea">
            <Input style="width: 260px" clearable placeholder="请输入圈舍面积(平方米)" v-model="form.stableArea"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="设计存栏(头、羽、只)" prop="designNum">
            <Input style="width: 260px" clearable placeholder="请输入设计存栏(头、羽、只)" v-model="form.designNum"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="现存栏数量(头、羽、只)" prop="currentNum">
            <Input style="width: 260px" clearable placeholder="请输入现存栏数量(头、羽、只)" v-model="form.currentNum"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="清粪模式" prop="manureRemoveMode">
            <Select clearable placeholder="请选择清粪模式" v-model="form.manureRemoveMode" style="width: 260px">
              <Option v-for="(item, index) in removeMode" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="污粪收集工艺" prop="manureProcessName">
            <Input clearable placeholder="请输入污粪收集工艺" v-model="form.manureProcessName" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="粪污设施是否配套" prop="facilityOk">
            <Select v-model="form.facilityOk" style="width: 260px">
              <Option value="0">否</Option>
              <Option value="1">是</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="消纳土地面积(亩)" prop="consumptiveArea">
            <Input style="width: 260px" clearable placeholder="请输入消纳土地面积(亩)" v-model="form.consumptiveArea"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="离河库最近距离(米)" prop="riverMeter">
            <Input style="width: 260px" clearable placeholder="请输入离河库最近距离(米)" v-model="form.riverMeter"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="监管人">
            <Input style="width: 260px" clearable placeholder="请输入监管人" v-model="form.supervisorName"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="监管人电话">
            <Input style="width: 260px" clearable placeholder="请输入监管人电话" v-model="form.supervisorPhone"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="利用率" prop="zRate">
            <Input clearable placeholder="请输入利用率" v-model="form.zRate" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="生产状态" prop="productionStatus">
            <Select style="width: 260px" clearable placeholder="请选择生产状态" v-model="form.productionStatus">
              <Option value="1">正常</Option>
              <Option value="2">临时性停产</Option>
              <Option value="3">停产</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <Modal v-model="mapModal" fullscreen>
      <search-map v-if="mapModal" :currentData="currentData" @back-location="dealMapData"></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModal = false)">提交</Button>
        <Button @click="() => (mapModal = false)">取消</Button>
      </p>
    </Modal>
    <farmers-detail v-model="farmersDetail.show" :farmersDetailInfo="farmersDetail.info" :farmListInfo="farmListInfo" :servicePathInfo="servicePathInfo"></farmers-detail>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
    <Modal :width="300" v-model="isDelete" title="是否删除" @on-ok="deletes" @on-cancel="
        () => {
          this.isDelete = false;
        }
      ">
      <p>确定删除该条消息？</p>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import searchMap from '@/components/searchMap'
import pictureView from '@/components/picture_view'
import CU from '@/common/util'
import farmersDetail from './farmersDetail.vue'
export default {
  name: '',
  components: {
    searchMap,
    pictureView,
    farmersDetail,
  },
  data() {
    return {
      yearList: [],
      table: {
        columns: [
          {
            title: '养殖户名称',
            key: 'farmerName',
            align: 'center',
            fixed: 'left',
            minWidth: 180,
            tooltip: true,
          },
          {
            title: '年份(年)',
            // minWidth: 140,
            align: 'center',
            key: 'year',
            minWidth: 140,
          },
          {
            title: '规模类型',
            // minWidth: 140,
            align: 'center',
            key: 'scaleInfoName',
            minWidth: 140,
          },
          {
            title: '镇街',
            tooltip: true,
            align: 'center',
            key: 'regionCodeName',
            minWidth: 180,
          },
          {
            title: '地址',
            minWidth: 150,
            align: 'center',
            key: 'farmerAddress',
            tooltip: true,
          },
          {
            title: '业主姓名',
            key: 'legalName',
            align: 'center',
            minWidth: 140,
          },
          {
            title: '联系电话',
            minWidth: 140,
            align: 'center',
            key: 'legalPhone',
          },
          {
            title: '养殖品种',
            align: 'center',
            minWidth: 140,
            key: 'typeName',
          },
          {
            title: '养殖用途',
            align: 'center',
            minWidth: 140,
            key: 'applicationName',
          },
          {
            title: '圈舍面积(㎡)',
            align: 'center',
            key: 'stableArea',
            minWidth: 120,
          },
          {
            title: '设计存栏(头、羽、只)',
            align: 'center',
            key: 'designNum',
            minWidth: 180,
          },
          {
            title: '现存栏量(头、羽、只)',
            align: 'center',
            key: 'currentNum',
            minWidth: 180,
          },
          {
            title: '清粪模式',
            align: 'center',
            key: 'manureRemoveModeName',
            minWidth: 140,
          },
          {
            title: '污粪收集工艺',
            align: 'center',
            key: 'manureProcessName',
            minWidth: 140,
          },
          {
            title: '治理设施是否配套',
            align: 'center',
            key: 'facilityOk',
            minWidth: 160,
            render: (createEle, params) => {
              switch (params.row.facilityOk) {
                case '0':
                  return createEle('span', '否')
                case '1':
                  return createEle('span', '是')
              }
            },
          },
          {
            title: '消纳土地面积(亩)',
            align: 'center',
            key: 'consumptiveArea',
            minWidth: 160,
          },
          {
            title: '离河库最近距离(米)',
            align: 'center',
            key: 'riverMeter',
            minWidth: 160,
          },
          {
            title: '监管人',
            align: 'center',
            key: 'supervisorName',
            minWidth: 140,
          },
          {
            title: '监管人电话',
            align: 'center',
            key: 'supervisorPhone',
            minWidth: 160,
          },
          {
            title: '利用率',
            align: 'center',
            key: 'zRate',
            minWidth: 140,
            render: (createEle, params) => {
              return createEle('span', params.row.zRate + '%')
            },
          },
          {
            title: '是否停养',
            align: 'center',
            key: 'productionStatus',
            minWidth: 140,
            render: (createEle, params) => {
              switch (params.row.productionStatus) {
                case 1:
                  return createEle('span', '正常')
                case 2:
                  return createEle('span', '临时性停产')
                case 3:
                  return createEle('span', '停产')
              }
            },
          },
          {
            title: '更新时间',
            align: 'center',
            key: 'updateTime',
            minWidth: 180,
            tooltip: true,
            ellipsis: true,
          },
          {
            title: '操作',
            align: 'center',
            width: 150,
            fixed: 'right',
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA('ledgerProcessing_view') && (
                    <a
                      onClick={() => this.checkInfo(row)}
                    >
                      详情
                    </a>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: '',
        show: false,
        submitLoading: false,
      },
      form: {
        id: '',
        farmerName: '',
        scaleInfo: '',
        regionCode: '',
        regionCodeName: '',
        region: [],
        farmerAddress: '',
        legalName: '',
        legalPhone: '',
        typeName: '',
        application: '',
        stableArea: '',
        designNum: '',
        currentNum: '',
        manureRemoveMode: '',
        manureProcessName: '',
        facilityOk: '',
        consumptiveArea: '',
        riverMeter: '',
        supervisorName: '',
        supervisorPhone: '',
        zRate: '',
        productionStatus: '',
      },
      scaleList: [],

      //省市区列表
      areaList: [],
      rules: {
        name: [{ required: true, message: '请填写联系人' }],
        farmerName: [{ required: true, message: '请填写企业名称' }],
        tel: [
          { required: true, message: '请填写联系电话' },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message)
                return
              }
              callback()
            },
            message: '手机号格式不正确',
            trigger: 'change',
          },
        ],
        farmerAddress: [{ required: true, message: '请填写养殖地址' }],
        scaleInfo: [{ required: true, message: '请选择规模类型' }],
        typeInfo: [{ required: true, message: '请选择养殖品种' }],
      },
      catData: [],
      selAddressShow: false,
      currentData: null,
      mapModal: false,
      companyData: [],
      manure: [],
      removeMode: [],
      application: [],
      picture: {
        show: false,
        src: '',
      },
      farmersDetail: {
        show: false,
        info: {},
      },
      cityList: [
        { value: '1', label: '是' },
        { value: '2', label: '否' },
      ],
      farmListInfo: [],
      servicePathInfo: '',
      deleteId: '',
      isDelete: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      this.yearList = []
      for (let i = 0; i < new Date().getFullYear() - 2022; i++) {
        this.yearList.push({
          value: String(2023 + i),
          id: String(2023 + i),
          title: String(2023 + i),
        })
      }
      let config = {
        filter: {
          loadExpend: {
            loadExpendName: '导出',
            ca: 'ledgerProcessing_export',
          },
          width: 220,
          filterBox: [
            {
              conditionName: '年份',
              component: 'select',
              field: 'year',
              defaultValue: '',
              data: this.yearList.reverse(),
              parameterField: 'id',
              showField: 'title',
              defaultValue: new Date().getFullYear().toString(),
              clearable: true,
            },
            {
              conditionName: '养殖户名称',
              component: 'input',
              field: 'farmerName',
              defaultValue: '',
            },
            {
              conditionName: '规模类型',
              component: 'select',
              field: 'scaleInfo',
              defaultValue: '',
              data: this.scaleList,
              parameterField: 'id',
              showField: 'name',
              clearable: true,
            },
            {
              conditionName: '镇街',
              component: 'cascader',
              field: 'regionCode',
              defaultValue: [],
              data: this.areaList,
              isLoadData: true,
              loadData: this.loadData,
            },
            {
              conditionName: '地址',
              component: 'input',
              field: 'farmerAddress',
              defaultValue: '',
            },
            {
              conditionName: '养殖品种',
              component: 'select',
              field: 'typeName',
              defaultValue: '',
              data: this.catData,
              parameterField: 'name',
              showField: 'name',
              clearable: true,
            },
            {
              conditionName: '企业类型',
              component: 'select',
              field: 'enterpriseType',
              defaultValue: '',
              data: this.companyData,
              parameterField: 'id',
              showField: 'name',
              clearable: true,
            },
          ],
        },
        page: this.page,
      }
      return config
    },
  },
  methods: {
    //导入
    loadEnter() {
      let input = document.createElement('input')
      input.type = 'file'
      input.onchange = (e) => {
        if (!input.files || input.files.length === 0) return
        //通过FormData构造函数创建一个空对象
        let formdata = new FormData()
        //通过append()方法来追加数据
        formdata.append('file', input.files[0])
        formdata.append('userId', localStorage.getItem('userId'))
        formdata.append('companyNo', localStorage.getItem('companyNo'))
        formdata.append('category', this.user.category + 1)
        this.$post(this.$api.FAMRMING.UPLOAD, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(() => {
          this.$Message.success('导入成功')
          this.getList()
        })
      }
      input.click()
    },
    dealMapData(mapData) {
      this.form.farmerAddress = mapData.address
      this.form.thirdLongLat = mapData.thirdLongLat
    },
    mapModelShow() {
      this.currentData = this.form.thirdLongLat
      this.mapModal = true
    },
    getList() {
      if (!this.CA('ledgerProcessing_check')) return
      this.table.loading = true
      this.$post(this.$api.FAMRMING.LEDGER_LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.records
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: '2',
      }).then((res) => {
        this.catData = res.list
      })
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '203',
      }).then((res) => {
        this.companyData = res.list
      })
    },
    //清粪模式
    getRemoveMode() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '541',
      }).then((res) => {
        this.removeMode = res.list
      })
    },
    //污粪收集工艺
    getManure() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '477',
      }).then((res) => {
        this.manure = res.list
      })
    },
    //养殖用途
    getApplication() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '538',
      }).then((res) => {
        this.application = res.list
      })
    },
    //规模类型
    getScale() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: 291,
      }).then((res) => {
        this.scaleList = res.list
      })
    },
    search(value) {
      console.log(value)
      let n
      if (value.regionCode) {
        for (let i = 0; i < value.regionCode.length; i++) {
          n = value.regionCode[value.regionCode.length - 1]
        }
        let obj = {
          ...value,
          regionCode: n,
        }
        this.search_data = obj
        this.getList()
      } else {
        let obj = {
          ...value,
        }
        this.search_data = obj
        this.getList()
      }
    },
    add() {
      this.modal = {
        show: true,
        title: '新增养殖户',
        submitLoading: false,
      }
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.typeInfo = []
      this.form.type = row.type.split(',')
      this.form.typeName = row.typeName.split(',')
      this.form.region = row.regionCode.split(',')
      this.form.logo = this.getImgUrl(row.servicePath, row.logo)
      if (this.form.farmCount) {
        this.form.farmCount = row.farmCount.toString()
      } else {
        this.form.farmCount = ''
      }

      if (this.form.productionStatus) {
        this.form.productionStatus = row.productionStatus.toString()
      } else {
        this.form.productionStatus = ''
      }
      for (let i = 0; i < row.type.length; i++) {
        let str = row.type[i] + '/' + row.typeName[i]
        this.form.typeInfo.push(str)
      }
      // console.log(this.form.typeInfo);
      // this.form.typeInfo = row.typeName
      this.modal = {
        show: true,
        title: '编辑养殖户',
        submitLoading: false,
      }
    },
    delete(id) {
      this.isDelete = true
      this.deleteId = id
    },
    deletes() {
      this.$post(this.$api.FAMRMING.DELETE, {
        id: this.deleteId,
      }).then(() => {
        this.$Message.success('删除成功！')
        this.getList()
      })
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500100',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          if (item.hasChild == 'true') {
            item.loading = false
            item.children = []
          }
        })
        this.areaList = res
      })
    },
    loadData(item, callback) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
          })
          item.children = res
          callback()
        })
        .finally(() => {
          item.loading = false
        })
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        let url
        if (params.id) {
          url = this.$api.FAMRMING.UPDATE
        } else {
          url = this.$api.FAMRMING.ADD
        }
        params.type = []
        params.typeName = []
        params.category = +this.user.category + 1
        this.form.typeInfo.forEach((item) => {
          params.type.push(item.split('/')[0])
          params.typeName.push(item.split('/')[1])
        })
        params.type = params.type.toString()
        params.farmCount = params.farmCount.toString()
        params.regionCode = this.form.region.join(',')
        delete params.region
        params.typeName = params.typeName.toString()
        delete params.typeInfo
        let index = this.form.logo ? this.form.logo.indexOf('/', 10) : -1
        if (index !== -1) {
          params.logo = this.form.logo.substring(index + 1)
        }
        this.modal.submitLoading = true
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? '修改成功！' : '增加成功！')
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    modalChange(visible) {
      if (visible) return
      this.form = {
        id: '',
        farmerName: '',
        scaleInfo: '',
        regionCode: '',
        region: [],
        farmerAddress: '',
        legalName: '',
        legalPhone: '',
        typeName: '',
        application: '',
        stableArea: '',
        designNum: '',
        currentNum: '',
        manureRemoveMode: '',
        manureProcessName: '',
        facilityOk: '',
        consumptiveArea: '',
        riverMeter: '',
        supervisorName: '',
        supervisorPhone: '',
        zRate: '',
        productionStatus: '',
      }
      this.$refs.form.resetFields()
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },

    loadExpend() {
      let params = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      }
      let url = this.$api.EXPORT.LEDGER
      this.$download(url, params, '粪污处理台账表')
    },
    //详情
    checkInfo(row) {
      this.$post(this.$api.FAMRMING.INFO, {
        companyNo: row.companyNo,
        userId: row.userId,
      }).then((res) => {
        this.farmListInfo = res.farmList
        this.servicePathInfo = res.servicePath
        this.farmersDetail = {
          show: true,
          info: row,
        }
      })
    },
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '统计',
          }
          return
        }
        const values = data.map((item) => Number(item[key]))
        if (column.key == 'designNum' || column.key == 'currentNum') {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: v,
            }
          } else {
            sums[key] = {
              key,
              value: '',
            }
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
      })
      return sums
    },
  },
  mounted() {
    this.getList()
    this.getCategory()
    this.getCompanyCategory()
    this.getManure()
    this.getApplication()
    this.getAreaList()
    this.getScale()
  },
}
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>
